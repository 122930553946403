import React, { useState, useEffect, useContext, createContext } from "react";
import * as Msal from 'msal';

const msalConfig = {
    auth: {
        clientId: "e08f2a0a-89cc-4c24-ab02-3cc98fa3fc70",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    }
};

const loginRequestScopes = {
    scopes: ["openid", "profile", "User.Read", "Files.ReadWrite.All"], // Define requested scopes from microsoft api
};

const tokenScope = {
  scopes: ["Files.ReadWrite.All"]
};

const myMsalObj = new Msal.UserAgentApplication(msalConfig);

const authContext = createContext();

export function ProvideMSAL({ children }) {
  const auth = useProvideMSAL();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useMSAL = () => {
  return useContext(authContext);
};

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(localStorageKey)) || ''
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);
  return [value, setValue];
};

function useProvideMSAL() {
  const [user, setUser] = useStateWithLocalStorage('userAuth');

  useEffect(() => {
    myMsalObj.acquireTokenSilent(tokenScope)
            .then((tokenResponse) => {
                // Callback code here
                setUser(tokenResponse);
                console.log("New token ready!");
                return tokenResponse.accessToken;
            })
            .catch((error) => {
                console.log("User logout can't create new token!");
                setUser('');
          });
  },[setUser])

  const signin = (email, password) => {
    return new Promise((resolve,reject) => {
      myMsalObj.loginPopup(loginRequestScopes)
        .then((loginResponse) => {
            //Login Success callback code here
            //console.log(loginResponse);
            //setUser(loginResponse);
            myMsalObj.acquireTokenSilent(tokenScope)
            .then((tokenResponse) => {
                // Callback code here
                setUser(tokenResponse);
                return tokenResponse.accessToken;
            })
            .then(() => {
              resolve(); // login sucessfull
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
        }).catch(function (error) {
            console.log(error);
            reject(error);
        })
    });
  };

  const getToken = (requestNewToken = false) => {
    return user.accessToken
  }

  const signout = () => {
    setUser(null);
    //return myMsalObj.logout(); // prevent redirect to microsoft
  };
  
  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    getToken
  };
}