import React from 'react';
import { useMSAL } from "../context/contextMsal.js";
import { useHistory } from 'react-router-dom'; 
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #5f92d4 30%, #4067b1 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 0px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

function LoginMSALButton(props) {
    const { hideIfLogin, ...rest } = props
    const auth = useMSAL();
    const history = useHistory();

    const signout = () => {
        auth.signout();
        history.push("/");
    }

    const singin = () => {
        history.push("/login")
        auth.signin()
        .then(() => history.push("/browse"))
        .catch(() => history.push("/"))
    }

    if(auth.user && hideIfLogin)
        return null;

    if(!auth.user)
      return (
          <StyledButton onClick={() => auth.user ? signout() : singin()} {...rest}>
              {auth.user ? "Logout" : props.children}
          </StyledButton>
      );
    else
        return (
          <>
          <StyledButton onClick={() => history.push("/browse")}>
              Upload Browser
          </StyledButton>
          <StyledButton onClick={() => auth.user ? signout() : singin()} {...rest}>
          {auth.user ? "Logout" : props.children}
          </StyledButton>
          </>
      );
}

export default LoginMSALButton
