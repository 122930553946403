import React from 'react';
import {useParams} from "react-router";
import OneDriveBrowser from './OneDriveBrowser'
//import FiledropBrowser from './FiledropBrowser'

function Browse() {
  let { id } = useParams();
//<FiledropBrowser />
  return (
        <OneDriveBrowser childId={id}/>
  );
}

export default Browse;