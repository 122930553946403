import React from "react"
//import Button from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
import LoginMSALButton from '../LoginMSALButton'

const FeatureGroup = styled('div')({
  display: 'flex',
  flexDirection: 'row'
});

const FeatureItem = styled('div')({
  flexBasis: '100%'
});

const FeatureItemPaper = styled('div')({
  flexBasis: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '25px',
  padding: '25px',
});

const PaperTitle = styled('h1')({
  fontSize: '36px',
  fontWeight: 'normal',
  color: '#C542FE',
});

const PaperText = styled('p')({
  fontSize: '18px',
  fontWeight: 'normal',
});

const Image = styled('img')({
  width: '100%',
});

const H1 = styled('h1')({
  fontSize: '48px',
  fontWeight: 'normal',
});

const P = styled('p')({
  fontSize: '24px',
  fontWeight: 'normal',
});

const FeatureTeaserText = styled('p')({
  fontSize: '18px',
  fontWeight: 'normal',
  margin: 0,
  padding: 0,
});

const FeatureHeaderText = styled('p')({
  fontSize: '36px',
  fontWeight: 'normal',
  color: '#C542FE',
  margin: 0,
  padding: 0,
});

const ScrollDownBox = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0, 8),
}));

const FeatureContainer = styled('div')(({theme}) => ({  
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(10, 0, 8),
}));

const useStyles = makeStyles((theme) => ({
  highlightBox: {
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.21)',
  },
  altContainer: {
    backgroundColor: '#fcfcfc',
  }
}));

const features = [
  {
    title: 'No install',
    description: 'You can start direct in your Browser wihtout any installation. Just login into your OneDrive Account and start uploading.',
  },
  {
    title: 'Security',
    description: 'We use the official API from Microsoft. Your files will always be uploaded directly to Microsoft via secure connection from your Browser.',
  },
  {
    title: 'Resume',
    description: 'With us you can restart your interrupted uploads. Deside if you want to overwrite or skip exisiting files.',
  },
];

const Landing = () => {
  const classes = useStyles();
  return (
    <>
    <FeatureGroup>
      <FeatureItem>
        <Image src="./images/ServerIsometricIcon.svg" alt="DeerDrive Easy OneDrive Upload" />
      </FeatureItem>
      <FeatureItem>
        <H1>The easiest way you can <b>Upload</b> your files</H1>
        <P>Uploading files from any storage location, e.g. external usb-stick, to Microsoft OneDrive has never been easier.</P>
        <LoginMSALButton hideIfLogin>Continue with Microsoft OneDrive</LoginMSALButton>
      </FeatureItem>
    </FeatureGroup>
    <ScrollDownBox>
      <img src="./images/SlideDownIcon.svg" alt="DeerDrive Scroll Down" />
    </ScrollDownBox>
    <FeatureContainer className={classes.altContainer}>
      <FeatureTeaserText>WHAT WE ARE DOING</FeatureTeaserText>
      <FeatureHeaderText>About Features</FeatureHeaderText>
      <img src="./images/Divider.svg" alt="" style={{paddingBottom:'45px'}} />
      <FeatureGroup>
      {features.map(({title, description}, index) => (
        <FeatureItemPaper key={index} className={(index===1)?classes.highlightBox:''}>
          <Image src="./images/FeatureIcon.svg" alt="Deer Drive Feature" />
          <PaperTitle>{title}</PaperTitle>
          <PaperText>{description}</PaperText>
        </FeatureItemPaper>
      ))}
      </FeatureGroup>
    </FeatureContainer>
    <FeatureContainer>
      <FeatureTeaserText>WHY WE ARE DOING THIS</FeatureTeaserText>
      <FeatureHeaderText>We believe to make a excellent service even better!</FeatureHeaderText>
      <img src="./images/Divider.svg" alt="" />
    </FeatureContainer>
    </>
  )
}
export default Landing;