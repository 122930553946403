const msRestApi = () => {
    const query = (graphUrl, token) => {
        const headers = new Headers({ Authorization: `Bearer ${token}` });
        const options = {
            headers
        };
        return fetch(`${graphUrl}`, options)
            .then(response => response.json())
            .catch(response => {
                throw new Error(response);
            });
    };

    const queryV2 = (graphUrl, token, data, header, method="GET") => {
        const headers = new Headers({
            Authorization: `Bearer ${token}`,
            ...header
        });
        const options = {
            method: method,
            body: data,
            headers
        };

        return fetch(`${graphUrl}`, options)
            .then(response => response.json())
    };


    return {
        query,        
        queryV2,
    };
}

const OneDriveApi = () => {
    const getRootList = (token) => { return msRestApi().query("https://graph.microsoft.com/v1.0/me/drive/root/children", token) }
    const getChildList = (token, id) => { return msRestApi().query(`https://graph.microsoft.com/v1.0/me/drive/items/${id}/children/`, token) }
    const getPath = (token, itemId) => { return msRestApi().query(`https://graph.microsoft.com/v1.0/me/drive/items/${itemId}`, token) }

    //
    // OneDrive Upload file
    // Docu: https://docs.microsoft.com/de-de/onedrive/developer/rest-api/api/driveitem_createuploadsession?view=odsp-graph-online
    //
    const createUploadSession = (token, filePath, data) => { return msRestApi().queryV2(`https://graph.microsoft.com/v1.0/drive/root:${filePath}:/createUploadSession`, token, data, {'Content-Type': 'application/json'}, "POST" )}
    const uploadChunk = (token, uploadUrl, contentLength, contentRange, data) => { return msRestApi().queryV2(uploadUrl, token, data, {'Content-Length': contentLength, 'Content-Range': contentRange}, "PUT") }

    //public functions
    return {
        getRootList,
        getChildList,
        getPath,

        // file upload logic functions
        createUploadSession,
        uploadChunk
    };
}

export default OneDriveApi