import React, { useState, useEffect } from 'react'
import { useMSAL } from "../../context/contextMsal.js";
import OneDriveApi from "../../util/oneDriveApi"
import BrowserItem from './BrowserItem'
import { useHistory } from 'react-router-dom';
import useUploadHandler from '../../util/useUploadHandler'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    browserContainer: {
        width: '80%',
        margin: '0 auto',
        "& label, & Button": {
            marginRight: '5px',
        }
    },
    pathFinder: {
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        "& span": {
            paddingLeft: '15px',
        }
    },
    menuBar: {
        paddingBottom: '15px',
    },
    loaderBox:
    {
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    table: {
      width: '100%',
    },
  });

const OneDriveBrowser = ({childId}) => {
    const classes = useStyles();
    const auth = useMSAL();
    const [loading, setLoading] = useState(true)
    const [listFiles, setlistFiles] = useState([])
    const [filepath, setFilepath] = useState('')
    const history = useHistory();
    const {
        files,
        uploadWorkerRun,
        onChange,
        onDrop,
        changeUploadFolder
      } = useUploadHandler(auth.getToken())

    const openFolderHandler = (item) => {
        setLoading(true);
        history.push(`/browse/${item.id}`);
    }

    useEffect(() => {
        auth.getToken(true)

        if(childId === undefined)
        {
            setFilepath('');
            OneDriveApi().getRootList(auth.getToken())
            .then(result => {
                setlistFiles(result)
                setLoading(false)
            });
        }
        else
        {
            OneDriveApi().getChildList(auth.getToken(), childId)
            .then(result => {
                setlistFiles(result)
                setLoading(false)
            });

            OneDriveApi().getPath(auth.getToken(), childId)
            .then(result => {
                setFilepath((result.parentReference.path + '/' + result.name).replace("/drive/root:",""));
                changeUploadFolder((result.parentReference.path + '/' + result.name).replace("/drive/root:",""));
            })
        }
    }, [auth, childId]) // NOT ADD changeUploadFolder
/*
    function traverseFileTree(item, path) {
        path = path || "";
        if (item.isFile) {
          // Get file
          item.file(function(file) {
            console.log("File:", path + file.name);
          });
        } else if (item.isDirectory) {
          // Get folder contents
          var dirReader = item.createReader();
          dirReader.readEntries(function(entries) {
            for (var i=0; i<entries.length; i++) {
              traverseFileTree(entries[i], path + item.name + "/");
            }
          });
        }
      }*/

    const handleDragEnter = e => {
        //console.log(e);
        e.preventDefault();
        e.stopPropagation();
      };
      const handleDragLeave = e => {
        //console.log(e);
        e.preventDefault();
        e.stopPropagation();
      };
      const handleDragOver = e => {
       // console.log(e);
        e.preventDefault();
        e.stopPropagation();
      };
     /* const handleDrop = ev => {
        ev.preventDefault();
        ev.stopPropagation();

        var items = ev.dataTransfer.items;
        for (var i=0; i<items.length; i++) {
            // webkitGetAsEntry is where the magic happens
            var item = items[i].webkitGetAsEntry();
            if (item) {
            traverseFileTree(item);
            }
        }
    }*/

    return (
        <div className={classes.browserContainer}>
            <div className={classes.menuBar}>
                <Button
                    disabled={!childId}
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => history.goBack()}>Back</Button>
                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                        history.push(`/temp`); history.goBack();}}>Refresh</Button>
                <Button
                    disabled={uploadWorkerRun}
                    variant="contained"
                    component="label"
                    color="default"
                    size="small"
                    endIcon={<CloudUploadIcon />}>Upload Folder
                    <input
                    type="file"
                    webkitdirectory="1" 
                    mozdirectory="1" 
                    onChange={onChange}
                    style={{ display: "none" }}
                    /></Button>    
                <Button
                    disabled={uploadWorkerRun}
                    variant="contained"
                    component="label"
                    color="default"
                    size="small"
                    onChange={onChange}
                    endIcon={<CloudUploadIcon />}
                    >Upload File<input
                    type="file"
                    multiple 
                    style={{ display: "none" }}
                    /></Button>    
            </div>    
            {files.length!==0 && (
                <Paper variant="outlined">
                    <LinearProgress variant="determinate" value={100/files.length*files.filter(obj => obj.status==="done").length} />
                    {files.filter(obj => obj.status!=="done").filter((obj,i) => i < 10).map((obj,index) => (
                        <>
                            <li key={index+"_li"} style={{opacity: (obj.status === "done") ? 0.2 : 1}}>{obj.relativePath} - {obj.percent}% - ID {obj.id} -{obj.status}</li>
                            <LinearProgress key={index+"_lin"} variant="determinate" value={obj.percent} />
                        </>
                    ))}
                </Paper>
            )}    
            <div className="list" 
                onDrop={onDrop}
                onDragOver={e => handleDragOver(e)}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}>
                <TableContainer component={Paper}>
                    <div className={classes.pathFinder}>
                        <Link href="/browse" style={{color: "black"}}><HomeIcon /></Link>
                        <span>OneDrive{filepath.replace("/drive/root:","")}/</span>
                    </div>
                    <Table className={classes.table} aria-label="OneDrive Cloud">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Last modified</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {loading ? [0,1,2,3,4,5].map((item,index) => (  
                            <BrowserItem key={index} />
                        )):(
                            (listFiles)?
                                listFiles.value.map((item,index) => (
                                    <BrowserItem key={index} item={item} openFolderHandler={openFolderHandler} />
                                ))
                            :
                                    "No content"
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default OneDriveBrowser
