import { useEffect, useReducer } from 'react'
import oneDriveApi from '../util/oneDriveApi'

const worker = {
    uploadFile({ file, id }, dispatch, uploadUrl) {
        return new Promise((resolve, reject) => {
            var fileSize = file.size;
            var chunkSize = 320*1024; // 320 Byte * 1000 to Kilobyte = 0,32 Megabyte chunk size
            var offset = 0;
            var chunkReaderBlock = null;

            chunkReaderBlock = function (_offset, length, _file) {
                var blob = _file.slice(_offset, length + _offset);

                oneDriveApi().uploadChunk("", uploadUrl, blob.size, "bytes "+_offset+"-"+(_offset+blob.size-1)+"/"+fileSize, blob)
                    .then((response) => {
                        const percent = Math.floor((_offset / fileSize) * 100);
                        dispatch({ type: 'setPercent', id, percent })
                        _offset += blob.size;
                        if (_offset >= fileSize) {
                            resolve("Done uploading file")
                            return;
                        }
                        chunkReaderBlock(_offset, chunkSize, file);
                    })
                    .catch(error => reject(error))
            }

            // now let's start the read with the first block
            chunkReaderBlock(offset, chunkSize, file);
        })
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'setParallelUploads':
            return { ...state, parallelUploads: state.parallelUploads + action.payload }
        case 'setStatus':
            const filesStatus = state.files.map((file) => {
                if (file.id === action.id)
                    return { ...file, status: action.status }
                return { ...file }
            })
            return { ...state, files: filesStatus }
        case 'setPercent':
            const files = state.files.map((file) => {
                if (file.id === action.id)
                    return { ...file, percent: action.percent }
                return { ...file }
            })
            return { ...state, files }
        case 'setWorker':
            return { ...state, uploadWorkerRun: action.payload }
        case 'setUploadFolder':
            return { ...state, uploadFolder: action.payload }
        case 'loadFiles':
            return { ...state, files: action.files }
        case 'addFile':
            return { ...state, files: [...state.files, action.newFile] }
        default:
            return state
    }
}

const useUploadHandler = (token) => {
    const [state, dispatch] = useReducer(reducer, { files: [], uploadWorkerRun: false, parallelUploads: 0, uploadFolder: "/" })
    const maxParallelUploads = 6;
    const overwriteFiles = false;

    const onSubmit = (e) => {
        dispatch({ type: 'setWorker', payload: true })
    }

    const changeUploadFolder = (newFolder) => {
        if(state.uploadWorkerRun === false)
            dispatch({ type: 'setUploadFolder', payload: newFolder+"/" })
        else
            console.warn("can't update upload folder while upload running")
    }

    function traverseFileTree(item,path){
        path = path || "";
        if (item.isFile){
            item.file(function (file){ 
                const src = window.URL.createObjectURL(file)
                dispatch({ type: 'addFile', newFile: { file, id: Math.random().toString(36).substr(2, 9), relativePath: item.fullPath.substring(1), src, percent: 0, status: 'wait' }})
                dispatch({ type: 'setWorker', payload: true })
             });
        } else if (item.isDirectory){
          var directoryPath = path + item.name
          var dirReader = item.createReader();
          dirReader.readEntries(function(entries){
            entries.map((entrie) => {
                return traverseFileTree(entrie, directoryPath + "/")
            })
          });
        }
      }

    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        var items = e.dataTransfer.items;
        var length = items.length;
        for (var i = 0; i < length; i++) {
            var item = items[i].webkitGetAsEntry();
            if(item){
                traverseFileTree(item);
            }
        }
    }

    const onChange = (e) => {
        if (e.target.files.length) {
            const arrFiles = Array.from(e.target.files)
            const files = arrFiles.map((file, index) => {
                const src = window.URL.createObjectURL(file)
                return { file, relativePath: file.webkitRelativePath, id: index, src, percent: 0, status: 'wait' }
            })
            dispatch({ type: 'loadFiles', files })
            dispatch({ type: 'setWorker', payload: true }) // start upload
        }
    }

    // perform upload worker
    useEffect(() => {
        if (state.uploadWorkerRun) {
            const nextUpFile = state.files.find(file => file.status === 'wait')
            if (nextUpFile && state.parallelUploads < maxParallelUploads) {
                // upload start
                dispatch({ type: 'setParallelUploads', payload: +1 })
                dispatch({ type: 'setStatus', id: nextUpFile.id, status: "uploading" })

                const conflictDecition = (overwriteFiles) ? "replace" : "fail";
                let uploadPathPlusFile = state.uploadFolder;
                uploadPathPlusFile += (nextUpFile.relativePath === "") ? nextUpFile.file.name : nextUpFile.relativePath;
                const data = '{"item": {"@microsoft.graph.conflictBehavior": "'+conflictDecition+'","name": "'+ nextUpFile.file.name +'"}}'; // add rule for exisitng files
                console.log(nextUpFile);
                oneDriveApi().createUploadSession(token, uploadPathPlusFile, data)
                    .then((response) => {                        
                        if(!response.uploadUrl)
                            return new Promise((resolve,reject) => reject(response))
                        return worker.uploadFile(nextUpFile, dispatch, response.uploadUrl)
                    })
                    .then((success) => {
                        console.log(success)
                        // upload done
                        dispatch({ type: 'setPercent', id: nextUpFile.id, percent: 100 })
                        dispatch({ type: 'setStatus', id: nextUpFile.id, status: "done" })
                        dispatch({ type: 'setParallelUploads', payload: -1 })
                    })
                    .catch((error) => {
                        console.log(error)
                        console.log(nextUpFile.id)
                        dispatch({ type: 'setPercent', id: nextUpFile.id, percent: 100 }) 
                        dispatch({ type: 'setStatus', id: nextUpFile.id, status: "error" })
                        dispatch({ type: 'setParallelUploads', payload: -1 })
                    })
            }
            else if (!nextUpFile && !state.files.find(file => file.status === 'uploading')) {
                dispatch({ type: 'setWorker', payload: false })
            }
        }
    }, [state, token, overwriteFiles])

    return { ...state, onChange, onDrop, onSubmit, changeUploadFolder }
}

export default useUploadHandler