import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            background: "#f5f5f5",
        }, 
    },
  });

function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

const BrowserItem = ({item, openFolderHandler}) => {
    const classes = useStyles();

    if(!item)
    {
        return (
            <TableRow>
                <TableCell><Skeleton variant="circle" width={40} height={40} /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
                <TableCell><Skeleton variant="text" /></TableCell>
            </TableRow>
        )
    }

    return (
        <TableRow key={item.id} onClick={(item.folder)?(() => openFolderHandler(item)):(null)} className={(item.folder)?classes.clickable:''}>
            <TableCell>
                {item.folder && (<FolderIcon />)}
                {item.file && (<FileIcon />)}
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{formatBytes(item.size)}</TableCell>
            <TableCell>{item.lastModifiedDateTime}</TableCell>
        </TableRow>
    )
}

export default BrowserItem;