import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { ProvideMSAL } from "./context/contextMsal";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMSAL } from "./context/contextMsal.js";

// Components
import FullDesign from './components/FullDesign'
import LandingPage from './components/frontend/Landing'
import SecurityPage from './components/frontend/Security'
import PolicyPage from './components/frontend/Policy'
import Browse from './components/backend/Browse'

// Style
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const OnlyAuthUsers = ({children, props}) => {

  const {user} = useMSAL();

  return (
    <>
      <Route exact path="/" component={LandingPage} />
      <Route path="/security" component={SecurityPage} />
      <Route path="/contact" component={PolicyPage} />
      {(user && (
        <>
          <Route exact path="/browse" component={Browse} />
          <Route path="/browse/:id" component={Browse} />
        </>
      ))}
      <Route path="/login">
          <center>Wait for login ...</center>
      </Route>
    </>
  )
}

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Monserrat", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    background: {
      default: 'white',
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ProvideMSAL>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <FullDesign>
              <OnlyAuthUsers />
            </FullDesign>
          </Switch>
        </Router>
      </ThemeProvider>
    </ProvideMSAL>
  </React.StrictMode>,
  document.getElementById('root')
);

console.log(`current environment: ${process.env.NODE_ENV}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();